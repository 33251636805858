import React, { useState, useMemo, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RealtimeBest from "./pages/community/RealtimeBest";
import PastBest from "./pages/community/PastBest";
import BestOfBest from "./pages/community/BestOfBest";
import RealtimeRanking from "./pages/community/RealtimeRanking";
import MonthlyRanking from "./pages/community/MonthlyRanking";
import YearlyRanking from "./pages/community/YearlyRanking";
import RealtimeSearch from "./pages/search/RealtimeSearch";
import PastSearch from "./pages/search/PastSearch";
import BestShopping from "./pages/shopping/BestShopping";
import RealtimeShopping from "./pages/shopping/RealtimeShopping";
import PastShopping from "./pages/shopping/PastShopping";
import RealtimeYoutube from "./pages/youtube/RealtimeYoutube";
import PastYoutube from "./pages/youtube/PastYoutube";

import { SettingsProvider, useSettings } from "./contexts/SettingsContext";
import AdSenseScript from "./components/AdSenseScript";

export const ScrollContext = React.createContext({ isVisible: true, setIsVisible: () => {} });

function RouteTracker() {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);

    return null;
}

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function AppContent() {
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);
    const { settings, updateSetting } = useSettings();

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: settings.darkMode ? "dark" : "light",
                },
            }),
        [settings.darkMode]
    );

    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        const handleScroll = () => {
            const currentScrollY = window.pageYOffset;
            setIsHeaderVisible(currentScrollY < lastScrollY || currentScrollY < 50);
            lastScrollY = currentScrollY;
        };

        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <ScrollContext.Provider value={{ isVisible: isHeaderVisible, setIsVisible: setIsHeaderVisible }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    <RouteTracker />
                    <ScrollToTop />
                    <Layout>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            <Route path="/community" element={<Navigate to="/community/best-of-best" replace />} />
                            <Route path="/community/best-of-best" element={<BestOfBest />} />
                            <Route path="/community/realtime-best" element={<RealtimeBest />} />
                            <Route path="/community/past-best" element={<PastBest />} />
                            <Route path="/community/realtime-ranking" element={<RealtimeRanking />} />
                            <Route path="/community/monthly-ranking" element={<MonthlyRanking />} />
                            <Route path="/community/yearly-ranking" element={<YearlyRanking />} />
                            <Route path="/search" element={<Navigate to="/search/realtime-best" replace />} />
                            <Route path="/search/realtime-search" element={<RealtimeSearch />} />
                            <Route path="/search/past-search" element={<PastSearch />} />
                            <Route path="/shopping" element={<Navigate to="/shopping/best-shopping" replace />} />
                            <Route path="/shopping/best-shopping" element={<BestShopping />} />
                            <Route path="/shopping/realtime-shopping" element={<RealtimeShopping />} />
                            <Route path="/shopping/past-shopping" element={<PastShopping />} />
                            <Route path="/youtube" element={<Navigate to="/youtube/realtime-youtube" replace />} />
                            <Route path="/youtube/realtime-youtube" element={<RealtimeYoutube />} />
                            <Route path="/youtube/past-youtube" element={<PastYoutube />} />
                        </Routes>
                    </Layout>
                </Router>
            </ThemeProvider>
        </ScrollContext.Provider>
    );
}

function App() {
    return (
        <SettingsProvider>
            <AdSenseScript />
            <AppContent />
        </SettingsProvider>
    );
}

export default App;
