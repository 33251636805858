import React from "react";
import { Box, Container, Typography, Link, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

function Footer() {
    const theme = useTheme();

    return (
        <Box
            component="footer"
            sx={{
                bgcolor: theme.palette.mode === "light" ? "background.paper" : "background.default",
                py: 2,
                borderTop: `1px solid ${theme.palette.divider}`,
                mt: "auto",
            }}
        >
            <Container maxWidth="lg">
                <Typography variant="body2" color="text.secondary" align="center">
                    {"Copyright © "}
                    <Link color="inherit" href="https://oneulbest.com/">
                        오늘베스트
                    </Link>{" "}
                    {new Date().getFullYear()}
                    {" • "}
                    모든 컨텐츠의 저작권은 해당 출처에 있습니다.
                    {" • "}
                    <Link component={RouterLink} to="/privacy-policy" color="inherit">
                        개인정보 처리방침
                    </Link>
                </Typography>
            </Container>
        </Box>
    );
}

export default Footer;
